import {lazy, createElement, useEffect} from "react"
import { useTitle } from "hooks"
//Main
const Homepage = lazy(() => import("components/Routes/Homepage"))
const Presentation = lazy(() => import("components/Routes/Presentation"))
const Contact = lazy(() => import("components/Routes/Contact"))

//Actions

const ActionsHomepage = lazy(() => import("components/Routes/Actions/ActionsHomepage"))
const ActionsRouter = lazy(() => import("components/Routes/Actions/ActionsRouter"))

//Other

const WDDProg = lazy(() => import("components/Routes/WDD/WDDProg"))
const LegalNotice = lazy(() => import("components/Routes/Others/LegalNotice"))
const WIP = lazy(() => import("components/Common/Page/WIP"))

//WDD
const WDDHomepage = lazy(() => import("components/Routes/WDD/WDDHomepage"))
const WDDGeneralPublic = lazy(() => import("components/Routes/WDD/WDDGeneralPublic"))
const WDDContent = lazy(() => import("components/Routes/WDD/WDDContent"))
const WDDLive = lazy(() => import("components/Routes/WDD/WDDLive"))

const ComponentsList = {
    Homepage,
    Presentation,
    Contact,
    WDDHomepage,
    WDDGeneralPublic,
    WDDProg,
    WDDContent,
    WDDLive,
    LegalNotice,
    ActionsHomepage,
    ActionsRouter,
    WIP
}

const RoutesTreatment = ({name, Component}) => {

    const {sendTitleRequest} = useTitle()

    useEffect(() => {
        sendTitleRequest("title." + name)
        //eslint-disable-next-line
    }, [])

    return(
        <div className={"page " + name + "Container"}>
            {
                Component ?
                createElement(ComponentsList[Component])
                :
                <WIP />
            }
        </div>
    )
}

export default RoutesTreatment