const List = ({list}) => {
    return(
        <ul>
            {
                list.map(e => (
                    <li key={e}>{e}</li>
                ))
            }
        </ul>
    )
}
export default List;