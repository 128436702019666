import "./Loader.scss"
const Loader = () => {
    return(
        <div className="loaderContainer">
            <div className="loader__name">
                <span>Chargement ...</span>
            </div>
            <div className="loader">
                <div className="loader__cube">
                </div>
                <div className="loader__cube">
                </div>
            </div>
        </div>
    )
}
export default Loader;