import { useEffect, useState } from "react"

const useIsMobile = () => {
    const [isMobile, setMobile] = useState(null)

    useEffect(() => {
        function handleResize() {
            if (window.matchMedia('(min-width: 950px)').matches){
                setMobile(false)
            }
            else{
                setMobile(true)
            }
        }
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    },[])

    return isMobile;
}

export default useIsMobile;