import MainHeader from "./MainHeader"
import MobileHeader from "./MobileHeader"
import Navigation from "assets/navigation.json"
import {useIsMobile} from "hooks"
const Header = () => {
    
    const isMobile = useIsMobile()

    return(
        <>
        {
            isMobile ?
                <MobileHeader navigation={Navigation} />
            :
                <MainHeader navigation={Navigation} />
        }
        </>
    )
}

export default Header