import { Error } from "../../StateIcon"
import './errorpage.scss'
import {Text} from "components/Common/Text"
import { VDJTitle } from "../VDJTitle"
const ErrorPage = ({error}) => {
    return(
        <div className="error">
            <VDJTitle />
            <div className="error__content">
                <div>
                    <div className="error__content--main">
                        <Text request="common.page.error.main" />
                    </div>
                    <div className="error__content--error">
                        <span><Text request="common.page.error.code.text" balise={false}/>{error}</span>
                    </div>
                    <div className="error__content--explanation">
                        <Text request={"common.page.error.code." + error}/>
                    </div>
                </div>
                
                <div className="error__content--svg">
                    <Error />
                </div>
                
            </div>
        </div>
    )
}

export {ErrorPage}
export default ErrorPage