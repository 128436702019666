import Logo from "assets/images/logo/LogoVDJ.svg"
import {Link} from "react-router-dom"
import './MainHeader.scss'
import {Text} from "components/Common/Text"
import {LangSelector} from "./LangSelector"
const MainHeader = ({navigation}) => {
    return(
        <div className="header">
            <div className="header__row">
                <div className="header__line">
                    <div className="header__line--item">
                        <Link to={navigation[0].url}><Text request="navigation.1"/></Link>
                    </div>
                    <div className="header__line--item">
                        <Link to={navigation[1].url}><Text request="navigation.2"/></Link>
                    </div>
                </div>
                <div className="header__logo">
                    <Link to="/">
                        <img alt="Logo" src={Logo} />
                    </Link>
                </div>
                <div className="header__line">
                    <div className="header__line--item">
                        <Link to={navigation[2].url}><Text request="navigation.3"/></Link>
                    </div>
                    <div className="header__line--item">
                        <Link to={navigation[3].url}><Text request="navigation.4"/></Link>
                    </div>
                </div>
            </div>
            {/*<LangSelector />*/}
        </div>
    )
}

export default MainHeader;