import './MobileHeader.scss'
import Logo from 'assets/images/logo/LogoLeaves.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { useCallback, useRef, useState } from 'react';
import { Link } from "react-router-dom"
import {Text} from "components/Common/Text"
import { MobileLangSelector } from './MobileLangSelector';
library.add(faBars)

function MobileHeader({navigation}) {
    const [isNavDeployed, setNavDeployed] = useState(false)
    
    const handleDeployClick = useCallback(() => {
        setNavDeployed(!isNavDeployed)
    }, [isNavDeployed])
    
    const handleNav = useCallback(() => {
        setNavDeployed(false)
    }, [])

    const node = useRef()

    return(
        <div className="mobileHeader">
            <div className='mobileHeader__mainline'>
                <div className='mobileHeader__mainline--logo'>
                    <Link to="/" onClick={handleNav}>
                        <img alt="Logo" src={Logo} />
                    </Link>
                </div>
                <div className='mobileHeader__mainline--deploy' onClick={handleDeployClick}>
                    <FontAwesomeIcon icon={['fas', 'bars']} />
                </div>
            </div>
            <div ref={node} className={'mobileHeader__deployedline' + (isNavDeployed ? ' deployed' : ' hidden')} style={{maxHeight: 
                    (isNavDeployed ? 
                        node.current.scrollHeight > (document.documentElement.clientWidth / 4) ? node.current.scrollHeight + 100 + "px": "max(25vh, 300px)" 
                        : "0px")}
                }>
                <div className="mobileHeader__deployedline--link">
                    <Link to={navigation[0].url} onClick={handleNav}><Text request="navigation.1"/></Link>
                </div>
                <div className="mobileHeader__deployedline--link">
                    <Link to={navigation[1].url} onClick={handleNav}><Text request="navigation.2"/></Link>
                </div>
                <div className="mobileHeader__deployedline--link">
                    <Link to={navigation[2].url} onClick={handleNav}><Text request="navigation.3"/></Link>
                </div>
                <div className="mobileHeader__deployedline--link">
                    <Link to={navigation[3].url} onClick={handleNav}><Text request="navigation.4"/></Link>
                </div>
                {/*<MobileLangSelector />*/}
            </div>
        </div>
    )
}

export default MobileHeader;