import LogoLeaves from "assets/images/logo/LogoLeaves.svg"
import './VDJtitle.scss'

const VDJTitle = () => {
    return(
        <div className="vdjtitle">
            <span>Visages de jeunes</span>
            <img src={LogoLeaves} alt="" />
        </div>
    )
}

export {VDJTitle}