import Text from "components/Common/Text/Query"
import './SectionTitle.scss'
const SectionTitle = ({title = "", isPure = false}) => {
    return (
        <h3 className="sectionTitle">
            {
                isPure ?
                <span>{title}</span>
                :
                <Text request={title} balise={false} />
            }
            
        </h3>
    )
}

export default SectionTitle;