import {MainContext} from "App.jsx"
import { useContext, useEffect, useState } from "react"
import JSONPath from "utils/JSONPath.js"
import List from "components/Common/Text/List"
const Text = ({request = "", balise = true}) => {
    const { lang } = useContext(MainContext)
    const [text, setText] = useState(null)

    useEffect(() => {
        import('assets/text/' + lang + '.json').then(textData => {
            var query = JSONPath(request, textData)
            if (query === undefined){
                import('assets/text/fr.json').then(textData => {
                    setText(JSONPath(request, textData))
                })
            }
            else{
                setText(query ? query : "")
            }
            
        })
        return () => setText('')
    },[lang, request])
    
    
    return(
        <>{Array.isArray(text) ?
            <List list={text} />
            :
            balise ?
                <span>{text}</span>
                :
                text
        }
        </>
    )
}
export default Text;