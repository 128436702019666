import {MainContext} from "App.jsx"
import {useContext, useEffect, useState} from "react";

const useAPI = ( url, method = "GET") => {
    const {apiURL} = useContext(MainContext)

    const [fetchData, setData] = useState({
        "state": "pending",
        "response_code_client": undefined
    })

    useEffect(() => {
        return () => setData(
            {"state": "canceled",
            "response_code_client": undefined}
        )
    }, [])

    const sendRequest = async (body) => {
        await fetch(apiURL + url, {
            method: method,
            credentials: "include",
            body: JSON.stringify(body),
        })
        .then(
            function(response) {
                if (response.status !== 200) {
                    setData({
                        "state": "error",
                        "response_code_client": response.status
                    })
                return;
                }
                response.json().then(function(data) {
                    if (data.response_code === 200){
                        setData({
                            "state": "success",
                            "response_code_client": response.status,
                            ...data
                        })
                        if (data.cookies){
                            data.cookies.forEach(element => {
                                document.cookie = element.name + "=" + element.value + ";max-age=" + element.expire + ";samesite=none;secure=true"
                            });
                        }
                    }
                    else{
                        setData({
                            "state": "error",
                            "response_code_client": response.status,
                            ...data
                        })
                    }
                });
            }
        )
        .catch(function(err) {
            setData({
                "state": "error",
                "response_code_client": err
            })
        });
    }
    return {fetchData, sendRequest}
}

export default useAPI;