import React, { useEffect, useState } from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import Routes from "./routes"
import Header from "./components/Header"
import './App.scss';


const AppData = {
    dev: {
        url: "http://localhost:3000",
        apiURL: "http://localhost/?appname=main&",
    },
    production: {
        url: "https://visagesdejeunes.fr",
        apiURL: "https://api.visagesdejeunes.fr/?appname=main&",
    }
}

export const MainContext = React.createContext(AppData.production)

function App() {

    const [lang, setLang] = useState(/*window.localStorage.getItem('lang') || */'fr')

    useEffect(() => {
        window.localStorage.setItem('lang', lang)
    }, [lang])

  return (
    <Router>
        <MainContext.Provider value={{apiURL: (document.location.origin === "https://localhost:8080" ? AppData.dev.apiURL : AppData.production.apiURL), lang, setLang}}>
            <Header />
            <div className="pageContainer">
                <Routes />
            </div>
        </MainContext.Provider>
    </Router>
  );
}

export default App;
