import JSONPath from "utils/JSONPath.js"

const useTitle = () => {
    const lang = "fr"

    const sendTitleRequest = async (request) => {
        import('assets/text/' + lang + '.json').then(textData => {
            var query = JSONPath(request, textData)
            if (query === undefined){
                import('assets/text/fr.json').then(textData => {
                    document.title = (JSONPath(request, textData) + " | VDJ")
                })
            }
            else{
                document.title = (query + " | VDJ")
            }
            
        })
    }
    return {sendTitleRequest}

}

export default useTitle;